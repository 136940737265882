<template>
  <div class="form-group">
    <div v-if="label"
         class="form-group__label-wrapper"
         :class="{ required }"
    >
      <label class="form-group__label">{{ label }}</label>
    </div>
    <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-radio-group
          v-if="inline"
          :options="options"
          class="app-radio"
          :class="{error: withError}"
          v-model="preselectedNull"
          :disabled="disabled"
          :aria-describedby="ariaDescribedby"
          @change="$emit('change', $event)"
      >
      </b-form-radio-group>
      <b-form-radio
          v-else
          v-for="(option, idx) in options"
          :key="idx"
          class="app-radio"
          :class="{error: withError}"
          :value="option.value"
          name="app-radio"
          :disabled="disabled"
          :aria-describedby="ariaDescribedby"
          @change="$emit('change', option.value)"
      >
        {{ option.text }}
      </b-form-radio>
    </b-form-group>
    <span v-if="helpMessage" class="form-text text-muted">{{ helpMessage }}</span>
    <p v-if="withError" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      default: ''
    },
    selected: {
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    withError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: i18n.t('error.field_is_required')
    },
    helpMessage: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    preselectedNull: null
  })
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}

.form-group__label-wrapper {
  &.required {
    position: relative;
    display: inline-block;

    :after {
      content: "*";
      position: absolute;
      color: #f64e60;
      font-weight: bold;
      right: -10px;
    }
  }
}

.error-message {
  position: absolute;
  bottom: -32px;
  left: 0;
  font-size: 0.9rem;
  color: #f64e60;
}

.app-radio.error {
  border-color: #f64e60;
}
</style>
